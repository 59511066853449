import * as React from 'react';
import Carousel from 'react-grid-carousel';

interface ICarousel {
  activeColor: string;
  autoPlay: number;
  children: Array<any>;
}

const CarouselComponent: React.FC<ICarousel> = ({
  activeColor,
  autoPlay,
  children,
}) => {
  return (
    <Carousel
      cols={4}
      rows={1}
      gap={11}
      responsiveLayout={[
        {
          breakpoint: 1200,
          cols: 3,
        },
        {
          breakpoint: 990,
          cols: 2,
        },
      ]}
      mobileBreakpoint={670}
      autoplay={autoPlay}
      showDots
      dotColorActive={activeColor}
      loop
    >
      {children.map((el, i) => (
        <Carousel.Item key={i}>{el}</Carousel.Item>
      ))}
    </Carousel>
  );
};

export default CarouselComponent;
