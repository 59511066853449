import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { styled, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CarouselComponent from 'components/carouselComponent';
// ? Next js
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  padding: theme.spacing(4),
  borderRadius: 10,
  justifyContent: 'center',
}));

interface IOurTeam {
  title: string;
  description: string;
  team_members: ITeamMember[];
}

const Team: React.FC<IProps<IOurTeam>> = ({ data }) => {
  const theme = useTheme();
  return (
    <Box sx={{ flexGrow: 1, py: 12 }}>
      <Container>
        <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 } }}>
          <Typography
            component="h2"
            color="text.primary"
            variant="h2"
            textAlign="center"
            gutterBottom
          >
            {data.title}
          </Typography>
          <Typography color="text.secondary" textAlign="center" gutterBottom>
            {data.description}
          </Typography>
        </Stack>

        <CarouselComponent
          autoPlay={10000}
          activeColor={theme.palette.primary.main}
        >
          {data.team_members.map((el, i) => (
            <Item
              sx={{ my: 6, borderRadius: 2 }}
              key={el._id}
              variant="outlined"
            >
              <Stack
                alignItems="center"
                justifyContent="center"
                sx={{
                  borderRadius: 500,
                  width: 200,
                  height: 200,
                  overflow: 'hidden',
                  boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
                  margin: 'auto',
                }}
              >
                <Image
                  src={`${API}${el.media.url}`}
                  alt={el.media.alternativeText}
                  layout="fixed"
                  objectFit="cover"
                  width={200}
                  height={200}
                  quality={100}
                />
              </Stack>

              <Divider sx={{ my: 3 }} />

              <Typography
                component="h3"
                variant="h4"
                textAlign="center"
                gutterBottom
              >
                {el.name}
              </Typography>
              <Typography component="h4" textAlign="center" gutterBottom>
                {el.description}
              </Typography>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ flexGrow: 1 }}
              >
                <Link href={`${el.RS.linkedIn}`} passHref>
                  <IconButton>
                    <LinkedInIcon />
                  </IconButton>
                </Link>

                <Link href={`${el.RS.twitter}`} passHref>
                  <IconButton>
                    <TwitterIcon />
                  </IconButton>
                </Link>
              </Stack>
            </Item>
          ))}
        </CarouselComponent>
      </Container>
    </Box>
  );
};

export default Team;
